import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function Header() {
  const [toggle, setToggle] = useState(false);

  const handleToggle = () => {
    setToggle(!toggle);
  };
  return (
    <>
    <div className="header">
      <div className="top-nav">
        <div className="container">
          <div className="top-flex">
            <div className="logo-section">
              <img src="./log.png" alt="logo not found" className="logo" />
            </div>
            <button
              className={`navbar-toggler`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
              onClick={() => handleToggle()}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="contact-section">
              <div className="email">
                <div className="icons">
                  <FontAwesomeIcon icon={faEnvelope} color="#192A30" />
                </div>
                <a href="mailto:jayminpatel6143@gmail.com" target="_blank" rel="noreferrer">jayminpatel6143@gmail.com</a>
              </div>
              <div className="contact">
                <div className="icons">
                  <FontAwesomeIcon icon={faPhone} color="#192A30" />
                </div>
                <a href="tel:+919978345404" target="_blank" rel="noreferrer">+919978345404</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-nav">
        <div className="container">
          <div className="bottom-flex">
            <div className={`navbar-main ${toggle === true ? 'mobile-header' : ''}`}>
              <ul className="navbar-list">
                <li><Link to={'/'}>Home</Link></li>
                <li> <Link to={'/about'}>About</Link></li>
                <li><Link to={'/vehical-fleet'}>Vehicle Fleet</Link></li>
                <li><Link to={'/contact'}>Contact Us</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="mobile-header">

    </div>
    </>
  );
}

export default Header;
