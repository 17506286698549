import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import Testimonial from "../Components/Testimonial";
import { Link } from "react-router-dom";

function HomePage() {

  return (
    <div className="homepage">
      <Header />
      <div className="banner-top background">
        <div className="container">
          <div className="top-2">
            <h5>WELCOME TO JGT</h5>
            <div className="banner-heading-cta">
              <h1>LUXURY WHEELS FOR YOUR NEXT DESTINATION</h1>
              <div className="right-box">
                <div className="box-content">
                  <div className="box-contact">
                    <FontAwesomeIcon icon={faPhone} />
                    <h5>
                      <a
                        href="tel:+919978345404"
                        target="_blank"
                        rel="noreferrer"
                      >
                        +919978345404
                      </a>
                    </h5>
                  </div>
                  <h2>BOOKING WITH EASE AND CONFIDENCE!</h2>
                </div>
                <button className="box-btn">BOOK YOUR CAR</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="banner-img">
        <img
          src="./Home-banner.png"
          alt="LUXURY WHEELS FOR YOUR NEXT DESTINATION"
        />
      </div>
      <div className="three-col">
        <div className="container">
          <div className="homepage-aboutsection">
            <div className="firstsection">
              <img src="./Diverse-Fleet-Selection-Icon.png" alt="Not Found" />
              <h4>DIVERSE FLEET SELECTION</h4>
              <p>
                Embark on your adventure with our diverse fleet featuring a wide
                range of vehicles to suit every traveler's preferences.
              </p>
            </div>
            <div className="secondsection">
              <img
                src="./Convenient-Booking-Process-Icon.png"
                alt="Not Found"
              />
              <h4>CONVENIENT BOOKING PROCESS</h4>
              <p>
                Experience hassle-free booking through our streamlined process,
                making it convenient to secure your desired vehicle for your
                journey.
              </p>
            </div>
            <div className="thirdsection">
              <img
                src="./Affordable-Pricing-Options-Icon.png"
                alt="Not Found"
              />
              <h4>AFFORDABLE PRICING OPTIONS</h4>
              <p>
                Explore our pricing, ensuring affordability without compromising
                on quality for your travel needs.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="car-company">
        <div className="container">
          <div className="car-company-inner">
            <div className="car-company-text">
              <h5>CAR BRANDS</h5>
              <h2>OUR FLEET OF CARS COMPANY</h2>
            </div>
            <div className="company">
              <div className="company-logo">
                <img src="./Toyota.png" alt="Toyota" />
                <img  src="./Suzuki.png" alt="Suzuki" />
              </div>
              <div className="company-logo">
                <img src="./Force-Motors.png" alt="Force Motors" />
                <img  src="./Chevrolet.png" alt="Chevrolet" />
              </div>  
            </div>
          </div>
        </div>
      </div>
      <div className="zig-zag">
        <div className="zig-zag-text-media background">
          <div className="zig-zag-media">
            <img src="./Journey.png" alt="Who we are" loading="lazy" />
          </div>
          <div className="zig-zag-content">
            <div className="zig-zag-text">
              <h2>AFFORDABLE CAR RENTALS FOR YOUR JOURNEY</h2>
              <p>
                Embark on your journey with peace of mind, knowing that our
                affordable car rentals offer both reliability and value. With a
                diverse range of vehicles to choose from, find the perfect
                option to suit your travel needs without breaking the bank.
              </p>
              <ul className="zig-zag-list">
                <li>
                  <FontAwesomeIcon icon={faCircleCheck} />
                  Wide Range of Affordable Vehicles
                </li>
                <li>
                  <FontAwesomeIcon icon={faCircleCheck} />
                  Flexible Rental Packages Available
                </li>
                <li>
                  <FontAwesomeIcon icon={faCircleCheck} />
                  Transparent Pricing, No Hidden Fees
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Testimonial />
      <div className="cta">
        <div className="container">
          <div className="cta-inner">
            <div className="cta-heading">
              <h3>
                Your Journey Starts Here: Find the Right Rental Car for Your
                Trip!
              </h3>
            </div>
            <Link to={'/contact'}>
            <button className="cta-contact-btn" >
              CONTACT US
            </button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default HomePage;
