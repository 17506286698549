import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

function CarsSlider() {
  return (
    <Carousel showThumbs={false} showStatus={false} autoPlay={true} infiniteLoop={true} showArrows={false} interval={3000} swipeable={false} >
      <div className="car-slider">
        <div className="car">
          <div className="car-image">
            <img src="./Traveller.png" alt="Traveller" />
          </div>
          <div className="car-features">
            <h4>Force Traveller</h4>
            <div className="car-details">
              <p>15 - 17 seaters</p>
              <p>Petrol Manual</p>
            </div>
          </div>
        </div>

        <div className="car">
          <div className="car-image">
            <img src="./Dzire.png" alt="Dzire" />
          </div>
          <div className="car-features">
            <h4>Suzuki Dzire</h4>
            <div className="car-details">
              <p>5 seaters</p>
              <p>Diesel Manual</p>
            </div>
          </div>
        </div>
      </div>
    {/* Slider 2 */}
      <div className="car-slider">
        <div className="car">
          <div className="car-image">
            <img src="./Innova.png" alt="Innova" />
          </div>
          <div className="car-features">
            <h4>Toyota Innova</h4>
            <div className="car-details">
              <p>7 seaters</p>
              <p>Petrol Manual</p>
            </div>
          </div>
        </div>

        <div className="car">
          <div className="car-image">
            <img src="./Tavera.png" alt="tavera" />
          </div>
          <div className="car-features">
            <h4>Chevrolet Tavera</h4>
            <div className="car-details">
              <p>7 seaters</p>
              <p>Petrol Manual</p>
            </div>
          </div>
        </div>
      </div>
    </Carousel>
  );
}

export default CarsSlider;
