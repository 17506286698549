import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";

function ContactPage() {
  return (
    <>
      <Header />
      <div className="contact-page">
        <div className="contact-banner">
          <div className="banner-top background">
            <div className="container">
              <div className="banner-text">
                <h5>CONTACT US</h5>
                <h1>CONTACT US & REACH OUT TO YOUR NEXT ADVENTURE</h1>
              </div>
            </div>
          </div>
          <div className="banner-img">
            <img
              src="./Contact-banner.png"
              alt="Luxury wheels for your next destination."
            />
          </div>
        </div>
        <div className="three-col">
          <div className="container">
            <div className="homepage-aboutsection">
              <div className="firstsection">
                <img src="./Phone.png" alt="Not Found" />
                <h4>PHONE</h4>
                <p>
                  <a href="tel:+919978345404" target="_blank" rel="noreferrer">
                    +91 9978345404
                  </a>
                </p>
                <p>
                  <a href="tel:+918735869294" target="_blank" rel="noreferrer">
                    +91 8735869294
                  </a>
                </p>
              </div>
              <div className="secondsection">
                <img src="./Email.png" alt="Not Found" />
                <h4>EMAIL</h4>
                <p>
                  <a
                    href="mailto:jayminpatel6143@gmail.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    jayminpatel6143@gmail.com
                  </a>
                </p>
              </div>
              <div className="thirdsection">
                <img src="./Location.png" alt="Not Found" />
                <h4>ADDRESS</h4>
                <p>A AB 2, YOGESHWAR APPT, Ahmedabad Gujarat 380024</p>
              </div>
            </div>
          </div>
        </div>
        <div className="contact-form">
          <div className="container">
            <div className="contact-text">
              <h5>CONTACT FORM</h5>
              <h2>GET IN TOUCH WITH US</h2>
            </div>
            <div className="form">
              <div className="form-fields row">
                <div className="field col-lg-6 col-sm-12">
                  <input
                    aria-required="true"
                    aria-invalid="false"
                    placeholder="First Name"
                    type="text"
                  />
                </div>
                <div className="field col-lg-6 col-sm-12">
                  <input
                    aria-required="true"
                    aria-invalid="false"
                    placeholder="Last Name"
                    type="text"
                  />
                </div>
                <div className="field col-lg-6 col-sm-12">
                  <input
                    aria-required="true"
                    aria-invalid="false"
                    placeholder="Mobile Number"
                    type="text"
                  />
                </div>
                <div className="field col-lg-6 col-sm-12">
                  <input
                    aria-required="true"
                    aria-invalid="false"
                    placeholder="Email"
                    type="email"
                  />
                </div>
                <div className="field col-sm-12">
                  <textarea
                    cols="40"
                    rows="10"
                    aria-required="true"
                    aria-invalid="false"
                    placeholder="Your Message.."
                  />
                </div>
                <div className="field col-sm-12">
                  <button className="submit-btn" type="submit">
                    SUBMIT
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ContactPage;
