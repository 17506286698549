import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import {  faInstagram,  faFacebook,  faWhatsapp,} from "@fortawesome/free-brands-svg-icons";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Testimonial from "../Components/Testimonial";
import Counter from "../Components/Counter";
import { Link } from "react-router-dom";

function AboutPage() {

  return (
    <>
      <Header />
      <div className="about-page">
        <div className="about-banner">
          <div className="banner-top background">
            <div className="container">
              <div className="banner-text">
                <h5>WELCOME TO JGT</h5>
                <h1>LUXURY WHEELS FOR YOUR NEXT DESTINATION</h1>
              </div>
            </div>
          </div>
          <div className="banner-img">
            <img
              src="./About-banner.png"
              alt="Luxury wheels for your next destination."
            />
          </div>
        </div>
        <Counter/>
        <div className="zig-zag">
          <div className="zig-zag-text-media background">
            <div className="zig-zag-media">
              <img src="./Who-we-are.png" alt="Who we are" loading="lazy" />
            </div>
            <div className="zig-zag-content">
              <div className="zig-zag-text">
                <h5>WHO WE ARE</h5>
                <h2>UNLEASH YOUR TRAVEL DREAMS, WE HANDLE THE WHEELS</h2>
                <p>
                  We're a team of travel enthusiasts dedicated to making your
                  journey truly exceptional. We go beyond providing vehicles; we
                  offer expertise, personalized attention, and a commitment to
                  exceeding your expectations.
                </p>
                {/* <p>
                  We're not just about wheels. We're about unlocking incredible
                  journeys. Explore in comfort, freedom, and style with our
                  diverse fleet and local expertise. Let us help you create
                  memories that last a lifetime.
                </p> */}
              </div>
            </div>
          </div>
        </div>
        <Testimonial />
        <div className="zig-zag-rev">
          <div className="zig-zag-rev-text-media background">
            <div className="zig-zag-rev-content">
              <div className="zig-zag-rev-text">
                <h5>WHAT WE DO</h5>
                <h2>DISCOVER UNFORGATTABLE JOURNEYS WITH JAY GOGA TRAVELS</h2>
                <p>
                  At Jay Goga Travels, we're passionate about creating seamless
                  and enriching travel experiences for you.
                </p>
                <ul className="zig-zag-rev-list">
                  <li>
                    <FontAwesomeIcon icon={faCircleCheck} />
                    We boast years of experience in the travel industry,
                    ensuring a smooth and well-coordinated journey.
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCircleCheck} />
                    We take the time to understand your needs and preferences,
                    crafting a tailor-made travel experience.
                  </li>
                </ul>
              </div>
            </div>
            <div className="zig-zag-rev-media">
              <img src="./What-we-do.png" alt="What we do" loading="lazy" />
            </div>
          </div>
        </div>
        <div className="team">
          <div className="container">
            <div className="team-inner">
              <div className="team-heading text-center">
                <h5>OUR TEAM</h5>
                <h2>MEET OUR EXPERTS</h2>
              </div>
              <div className="team-cards">
                <div className="team-member">
                  <div className="member-img">
                    <img src="./Dummy.png" alt="Jaymin Patel" />
                  </div>
                  <div className="member-details">
                    <div className="member-social-media">
                      <FontAwesomeIcon icon={faInstagram} />
                      <FontAwesomeIcon icon={faFacebook} />
                      <FontAwesomeIcon icon={faWhatsapp} />
                    </div>
                    <div className="member-name">
                      <h4>Jaymin Patel</h4>
                      <p>CEO</p>
                    </div>
                  </div>
                </div>
                <div className="team-member">
                  <div className="member-img">
                    <img src="./Dummy.png" alt="Jaymin Patel" />
                  </div>
                  <div className="member-details">
                    <div className="member-social-media">
                      <FontAwesomeIcon icon={faInstagram} />
                      <FontAwesomeIcon icon={faFacebook} />
                      <FontAwesomeIcon icon={faWhatsapp} />
                    </div>
                    <div className="member-name">
                      <h4>Manthan Patel</h4>
                      <p>Co-Founder</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cta">
          <div className="container">
            <div className="cta-inner">
              <div className="cta-heading">
                <h3>
                  Your Journey Starts Here: Find the Right Rental Car for Your
                  Trip!
                </h3>
              </div>
              <Link to={"/contact"}>
                <button className="cta-contact-btn">CONTACT US</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AboutPage;
