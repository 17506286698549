import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import {
  faInstagram,
  faFacebook,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footer">
      <div className="container">
        <div className="footer-inner">
          <div className="footer-inner-1">
            <img src="./log.png" alt="logo not found" className="footer-logo" />
            <p>
              Discover the excitement of the open highway and the liberty to
              adventure with our wide selection of top-quality rental vehicles.
            </p>
            <div className="timing">
              <FontAwesomeIcon icon={faClock} />
              <p>Mon - Sat: 09:00am - 10:00pm</p>
            </div>
          </div>
          <div className="footer-inner-2">
            <h5>QUICK LINKS</h5>
            <ul className="footer-list">
              <li>
                <Link to={"/"}>Home</Link>
              </li>
              <li>
                {" "}
                <Link to={"/about"}>About</Link>
              </li>
              <li>
                <Link to={"/"}>Vehicle Fleet</Link>
              </li>
              <li>
                <Link to={"/contact"}>Contact Us</Link>
              </li>
            </ul>
          </div>
          <div className="footer-inner-3">
            <h5>GET IN TOUCH</h5>
            <p>A AB 2, YOGESHWAR APPT, Ahmedabad Gujarat 380024</p>
            <div className="footer-contact">
              <div className="mail-sec">
                <FontAwesomeIcon icon={faEnvelope} color="#192A30" />
                <a
                  href="mailto:jayminpatel6143@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  jayminpatel6143@gmail.com
                </a>
              </div>
              <div className="call-sec">
                <FontAwesomeIcon icon={faPhone} color="#192A30" />
                <div className="mobile-numbers">
                  <div className="number-1">
                    <a
                      href="tel:+919978345404"
                      target="_blank"
                      rel="noreferrer"
                    >
                      +919978345404
                    </a>
                  </div>
                  <div className="number-2">
                    <a
                      href="tel:+918735869294"
                      target="_blank"
                      rel="noreferrer"
                    >
                      +918735869294
                    </a>
                  </div>
                </div>
              </div>
              <div className="social-media">
                <div className="facebook">
                  <a
                    href="https://www.instagram.com/Jaygoga_travels_22"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon icon={faFacebook} />
                  </a>
                </div>
                <div className="instagram">
                  <a
                    href="https://www.instagram.com/Jaygoga_travels_22"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                </div>
                <div className="whatsapp">
                  <a
                    href="https://api.whatsapp.com/send?phone=+919978345404"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon icon={faWhatsapp} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          <p>© 2024 All Rights Reserved By Jay Goga Travels</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
