import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import CarsSlider from "../Components/CarsSlider";

function VehicalFleet() {
  return (
    <div className="vehicalfleet-page">
      <Header />
      <div className="vehical-slider">
        <div className="container">
          <div className="vehical-slider-inner">
            <div className="vehical-fleet-text text-center">
              <h2>DISCOVER OUR FLEET OF CARS</h2>
            </div>
            <CarsSlider />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default VehicalFleet;
