import React from "react";
import CountUp from "react-countup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapLocationDot, faCar, faBriefcase } from "@fortawesome/free-solid-svg-icons";

function Counter(props) {
  return (
    <>
      <div className="counter">
        <div className="container">
          <div className="counter-inner">
            <div className="counter-box">
              <div className="counter-number">
                <CountUp
                  start="0"
                  end="10"
                  duration="5"
                  separator=" "
                  decimals="0"
                  decimal=""
                  prefix=""
                  suffix="+"
                >
                  {({ countUpRef, start }) => (
                    <span ref={countUpRef} />
                  )}
                </CountUp>
              </div>
              <div className="counter-details">
                <FontAwesomeIcon icon={faBriefcase} />
                <p>Years of experience</p>
              </div>
            </div>
            <div className="counter-box">
              <div className="counter-number">
                <CountUp
                  start="0"
                  end="43"
                  duration="5"
                  separator=" "
                  decimals="0"
                  decimal=""
                  prefix=""
                  suffix="+"
                >
                  {({ countUpRef, start }) => (
                    <span ref={countUpRef} />
                  )}
                </CountUp>
              </div>
              <div className="counter-details">
                <FontAwesomeIcon icon={faMapLocationDot} />
                <p>Number of journey</p>
              </div>
            </div>
            <div className="counter-box">
              <div className="counter-number">
                <CountUp
                  start="0"
                  end="17"
                  duration="5"
                  separator=" "
                  decimals="0"
                  decimal=""
                  prefix=""
                  suffix="+"
                >
                  {({ countUpRef, start }) => (
                    <span ref={countUpRef} />
                  )}
                </CountUp>
              </div>
              <div className="counter-details">
                <FontAwesomeIcon icon={faCar} />
                <p>Number of cars</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Counter;
