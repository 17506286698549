import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

function Testimonial() {
  return (
    <div className="testimonial">
      <div className="container">
        <div className="testimonial-heading">
          <h5>CLIENT REVIEWS</h5>
          <h2>WHAT OUR CLIENT SAYS</h2>
        </div>
        <div className="testimonial-quote">
          <img src="./Quote-Icon.png" alt="Quote" />
          <Carousel
            showThumbs={false}
            showStatus={false}
            autoPlay={true}
            infiniteLoop={true}
            interval={2000}
            showArrows={false}
          >
            <div className="quote-text">
              <p>
                Amazing experience! The car was in perfect condition, and the
                rental process was seamless. Highly recommend this service for
                anyone looking for reliable and affordable transportation.
              </p>
              <h4>Fenil Patel</h4>
              <div className="rating-stars">
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
              </div>
            </div>
            <div className="quote-text">
              <p>
                Outstanding service! From booking to drop-off, everything was
                smooth. The staff was friendly and helpful, making our trip
                stress-free. Will definitely rent from them again!
              </p>
              <h4>Rajesh Gupta</h4>
              <div className="rating-stars">
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
              </div>
            </div>
            <div className="quote-text">
              <p>
                Absolutely satisfied with our rental experience. The vehicle was
                clean and well-maintained, and the customer service exceeded our
                expectations. Will be our go-to choice for future trips!
              </p>
              <h4>Amit Patel</h4>
              <div className="rating-stars">
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  );
}

export default Testimonial;
